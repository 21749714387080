<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-sys__log-error">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-input v-model="dataForm.module" :placeholder="$t('logError.module')" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="export" @click="exportHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" style="width: 100%;" :cell-style="{padding: '2px 0'}">
        <el-table-column prop="module" :label="$t('logError.module')" sortable="custom" align="center" width="120"></el-table-column>
        <el-table-column prop="requestUri" :label="$t('logError.requestUri')" sortable="custom"></el-table-column>
        <el-table-column prop="requestMethod" :label="$t('logError.requestMethod')" sortable="custom" align="center" width="100"></el-table-column>
        <el-table-column prop="requestParams" :label="$t('logError.requestParams')" sortable="custom" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="ip" :label="$t('logError.ip')" sortable="custom" align="center" width="140"></el-table-column>
        <el-table-column prop="userAgent" label="用户设备" sortable="custom" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="createDate" :label="$t('logError.createDate')" sortable="custom" align="center" width="160"></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" align="center" width="150">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="infoHandle(scope.row.errorInfo)">{{ $t('logError.errorInfo') }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: true,
        getDataListURL: '/sys/log/error/page',
        getDataListIsPage: true,
        exportURL: '/sys/log/error/export'
      },
      dataForm: {
        module: ''
      }
    }
  },
  methods: {
    // 异常信息
    infoHandle (info) {
      this.$alert(info, this.$t('logError.errorInfo'), {
        customClass: 'mod-sys__log-error-view-info'
      })
    }
  }
}
</script>

<style lang="scss">
.mod-sys__log-error {
  &-view-info {
    width: 1000px !important;
    .el-message-box__message {
      height: 580px !important;
      overflow: auto !important;
    }
  }
}

</style>
