<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__superviseinstruction">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <contract-cascader @change="contractChange"></contract-cascader>
        </el-form-item>
        <!-- <el-form-item>
          <el-date-picker
            v-model="publishDaterange"
            type="daterange"
            value-format="yyyy-MM-dd"
            :range-separator="$t('datePicker.range')"
            start-placeholder="签发开始日期"
            end-placeholder="签发结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="signDaterange"
            type="daterange"
            value-format="yyyy-MM-dd"
            :range-separator="$t('datePicker.range')"
            start-placeholder="签收开始日期"
            end-placeholder="签收结束日期">
          </el-date-picker>
        </el-form-item> -->
        <el-form-item>
          <el-input v-model="dataForm.publisher" placeholder="签发人" clearable style="width: 150px"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.signer" placeholder="签收人" clearable style="width: 150px"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.supervise" placeholder="监理机构" clearable style="width: 150px"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group v-if='dataForm.contractId' style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('opm:superviseinstruction:save')" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('opm:superviseinstruction:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;">
        <el-table-column type="selection" align="center" width="50"></el-table-column>
        <el-table-column prop="publishDate" label="签发日期" width="100" align="center" sortable="custom" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="viewHandle(scope.row.id)">{{scope.row.publishDate}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="publisher" label="签发人" width="140" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="supervise" label="监理机构" min-width="150" align="left" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="signer" label="签收人" width="140" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="signDate" label="签收日期" width="100" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
<!--        <el-table-column label="在线编辑" header-align="center" align="center" width="90">-->
<!--          <template v-slot="{row}">-->
<!--            <el-button type="text" size="small" @click="docReadOnly(row.id)">查看</el-button>-->
<!--            <el-button type="text" size="small" @click="docEdit(row.id)">编辑</el-button>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column :label="$t('handle')" fixed="right" align="center" width="250">
          <template v-slot="{row}">
            <table-button role="print" v-if="$hasPermission('opm:superviseinstruction:print')" @click="printHandle(row.id)"></table-button>
            <table-button role="update" v-if="$hasPermission('opm:superviseinstruction:update')" @click="addOrUpdateHandle(row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('opm:superviseinstruction:delete')" @click="deleteHandle(row.id)"></table-button>
            <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('opm:superviseinstruction:update'))"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
  import mixinViewModule from '@/mixins/view-module'
  import AddOrUpdate from './superviseinstruction-add-or-update'
  import ViewPage from './superviseinstruction-view'
  import Cookies from 'js-cookie'
  import qs from 'qs'
  import {officeUtil} from "@/utils/officeUtil";
  export default {
    mixins: [mixinViewModule],
    data () {
      return {
        mixinViewModuleOptions: {
          getDataListURL: '/opm/superviseInstruction/page',
          getDataListIsPage: true,
          exportURL: '/opm/superviseInstruction/export',
          deleteURL: '/opm/superviseInstruction',
        },
        publishDaterange: null, // 签发日期选择区间
        signDaterange:null, // 签收日期选择区间
        dataForm: {
          prjId:'',
          contractId:'',
          supervise: '',
          publisher:'',
          signer:'',
          publishStartDate: '',
          publishEndDate: '',
          signStartDate: '',
          signEndDate: '',
        },
      }
    },
    watch: {
      publishDaterange (val) {
        if (val == null) {
          this.dataForm.publishStartDate = null
          this.dataForm.publishEndDate = null
        } else {
          this.dataForm.publishStartDate = val[0]
          this.dataForm.publishEndDate = val[1]
        }
      },
      signDaterange (val) {
        if (val == null) {
          this.dataForm.signStartDate = null
          this.dataForm.signEndDate = null
        } else {
          this.dataForm.signStartDate = val[0]
          this.dataForm.signEndDate = val[1]
        }
      },
    },
    components: {
      AddOrUpdate,
      ViewPage
    },
    methods: {
      // 标段切换事件
      contractChange (data) {
        this.dataForm.prjId = data.prjId
        this.dataForm.contractId = data.contractId
        this.getDataList()
      },

      // 新增 / 修改
      addOrUpdateHandle (id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.dataForm.id = id
          this.$refs.addOrUpdate.dataForm.contractId = this.dataForm.contractId
          this.$refs.addOrUpdate.init()
        })
      },
      // 打印监理指令单
      printHandle(id) {
        var params = qs.stringify({
          'access_token': Cookies.get('access_token'),
          'tenant_code_key': Cookies.get('tenantCode'),
          'id': id,
          'prjId': this.dataForm.prjId
        })
        window.open(`${window.SITE_CONFIG['apiURL']}/opm/superviseInstruction/print?${params}`)
      },
      docReadOnly(id) {
        officeUtil.docReadOnly(id, 'word1')
      },
      docEdit(id) {
        officeUtil.docEdit(id, 'word1')
      },
    }
  }
</script>