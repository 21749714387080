<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-sys__post">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-input v-model="dataForm.postCode" :placeholder="$t('post.postCode')" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.postName" :placeholder="$t('post.postName')" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <dict-select v-model="dataForm.status" dict-type="post_status" :placeholder="$t('post.status')" style="width: 100px"></dict-select>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('sys:post:save')" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('sys:post:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;" :cell-style="{padding: '2px 0'}">
        <el-table-column type="selection" align="center" width="50"></el-table-column>
        <el-table-column prop="postCode" :label="$t('post.postCode')" align="center" width="240" sortable="custom"></el-table-column>
        <el-table-column prop="postName" :label="$t('post.postName')" align="left" sortable="custom"></el-table-column>
        <!-- <el-table-column prop="sort" :label="$t('post.sort')" header-align="center" align="center" width="140"></el-table-column> -->
        <el-table-column prop="status" :label="$t('post.status')" align="center" width="140" sortable="custom">
          <template slot-scope="scope">
            {{ $getDictLabel("post_status", scope.row.status) }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="140">
          <template slot-scope="scope">
            <table-button role="update" v-if="$hasPermission('sys:post:update')" @click="addOrUpdateHandle(scope.row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('sys:post:delete')" @click="deleteHandle(scope.row.id)"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './post-add-or-update'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: true,
        getDataListURL: '/sys/post/page',
        getDataListIsPage: true,
        deleteURL: '/sys/post',
      },
      dataForm: {
        postCode: '',
        postName: '',
        status: ''
      }
    }
  },
  watch: {
  },
  components: {
    AddOrUpdate
  }
}
</script>
