<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__materialtype">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="add" @click="addChildNode({id:0})" v-if="$hasPermission('mps:materialtype:save')"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('mps:materialtype:delete')" @click="myDeleteHandle()"></toolbar-button>
            <toolbar-button name="刷新名称路径" icon="el-icon-refresh" v-if="$hasPermission('mps:materialtype:update')" @click="refreshHandle"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table
        v-if="tableShow"
        v-loading="dataListLoading"
        :data="dataList"
        highlight-current-row
        border
        @sort-change="dataListSortChangeHandle"
        @selection-change="dataListSelectionChangeHandle"
        lazy
        ref="table"
        row-key="id"
        :load="loadTableData"
        :row-style="{height: '0px'}"
        :cell-style="{padding: '2px 0'}"
        style="width: 100%;">
        <el-table-column type="selection" align="center" width="50"></el-table-column>
        <el-table-column prop="code" label="编号" width="250" align="left" show-overflow-tooltip></el-table-column>
        <el-table-column prop="name" label="名称" min-width="150" align="left" show-overflow-tooltip></el-table-column>
        <el-table-column prop="remark" label="备注" width="200" align="left" show-overflow-tooltip></el-table-column>
        <!--<el-table-column prop="sortNo" label="排序" width="150" header-align="center" align="center" show-overflow-tooltip></el-table-column>-->
        <el-table-column :label="$t('handle')" fixed="right" align="center" width="220">
          <template slot-scope="scope">
            <table-button role="addChild" @click="addChildNode(scope.row)" v-if="$hasPermission('mps:materialtype:save')"></table-button>
            <table-button role="edit" @click="addOrUpdateHandle(scope.row.id)" v-if="$hasPermission('mps:materialtype:update')"></table-button>
            <table-button role="delete" @click="myDeleteHandle(scope.row.id, scope.row.pid)" v-if="$hasPermission('mps:materialtype:delete')"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @addNode="addNodeCallback" @updateNode="updateNodeCallback" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
  import mixinViewModule from '@/mixins/view-module'
  import AddOrUpdate from './materialtype-add-or-update'

  export default {
    mixins: [mixinViewModule],
    data () {
      return {
        mixinViewModuleOptions: {
          exportURL: '/mps/materialType/export',
          deleteURL: '/mps/materialType',
          deleteWithRelatedFiles: false,
          deleteSuccessCallback: this.deleteSuccessCallback
        },
        dataForm: {
          name: '',
          code: '',
        },
        dataList: [],
        dataListLoading: false,
        findChildrenUrl: '/mps/materialType/findChildren',
        // 存储已加载的节点的map
        treeNodeMap: new Map(),
        tableShow: true,
      }
    },
    watch: {},
    components: {
      AddOrUpdate
    },
    created () {
      this.getDataList()
    },
    methods: {

      getDataList () {
        this.treeNodeMap = new Map()
        // 重置一些el-table缓存的变量
        // 此处如果不重置，可能会导致一些无法预料的情况
        // 例如：某些节点被展开过，刷新后依然展开，其中的数据是缓存的而不是最新的
        this.tableShow = false
        this.$nextTick(() => {
          this.tableShow = true
        })
        this.dataListLoading = true
        this.$http.get(
          this.findChildrenUrl,
          {
            params: {
              pid: 0,
            }
          }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            // 返回出错
            return this.$message.error(res.msg)
          }
          this.dataList = res.data
          this.dataListLoading = false
        }).catch(() => {
          this.dataListLoading = false
          // 查询出错
          return this.$message.error('出错了')
        })
      },

      // el-table懒加载节点
      loadTableData (data,node,resolve) {
        let pid = data.id
        // 将已加载的节点相关参数存入map，用于后续增加子节点、删除子节点时刷新父节点
        this.treeNodeMap.set(pid,{data,node,resolve})
        this.$http.get(
          this.findChildrenUrl,
          {
            params: {
              pid: pid
            }
          }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            // 返回出错
            return this.$message.error(res.msg)
          }
          resolve(res.data);
          // 如果子节点数量为0，则说明这一次的load是在删除了最后一个子节点后进行的，需要删除lazyTreeNodeMap中对应的数据
          // 否则按照el-table的基础行为，此子节点删除后依然会显示在table中，视图不会更新
          if (res.data.length == 0) {
            this.$set(this.$refs.table.store.states.lazyTreeNodeMap,pid,[])
          }
        }).catch(() => {
          // 查询出错
          return this.$message.error('出错了')
        })
      },

      // 新增子节点
      addChildNode (row) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.dataForm.pid = row.id
          this.$refs.addOrUpdate.init()
        })
      },
      deleteSuccessCallback({id,pid}) {
        if (id && pid) {
          this.refreshParentNode(pid)
        } else {
          this.getDataList()
        }
      },
      // 删除
      myDeleteHandle (id,pid) {
        this.deleteHandle(id, {deleteSuccessCallbackArgs: {id,pid}, autoQuery: false})
      },

      addNodeCallback (pid) {
        this.refreshParentNode(pid)
      },

      updateNodeCallback (pid,isPidChanged) {
        if(isPidChanged){
          // 父节点修改，直接刷新列表
          this.getDataList()
        }else{
          // 父节点未修改，刷新该节点子节点列表
          this.refreshParentNode(pid)
        }
      },

      // 刷新父节点（只能刷新展开过的节点）
      refreshParentNode (pid) {
        let nodeInfo = this.treeNodeMap.get(pid)
        if (nodeInfo) {
          // 这个loading属性控制此节点在load时是否重新加载
          // 例：在删除某个子节点后，若父节点loading属性为false，则父节点重新加载后，被删除的子节点依然显示
          nodeInfo.node.loading = true
          this.loadTableData(nodeInfo.data,nodeInfo.node,nodeInfo.resolve)
        } else {
          this.getDataList()
        }
      },

      // 刷新节点的名称路径(pids,pnames)
      refreshHandle () {
        this.$confirm('此操作将刷新物资分类的名称路径, 是否继续?').then(() => {
          const loading = this.$loading({
            lock: true,
            text: '请稍候...',
            spinner: 'el-icon-loading',
            customClass: 'my-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          this.$http.put(`/mps/materialType/refresh`).then(({data: res}) => {
            if (res.code != 0) {
              return this.$alert(res.msg,'',{
                confirmButtonText: '关闭',
                type: "error"
              });
            }
            this.$alert('刷新成功','',{
              confirmButtonText: '关闭',
              type: "success"
            });
          }).finally(() => {
            loading.close()
          })
        }).catch(() => {
          //  do nothing
        });
      }
    }
  }
</script>