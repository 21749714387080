<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-ureport__model">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-input v-model="dataForm.fileName" :placeholder="$t('fileName')" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="delete" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table
        v-loading="dataListLoading"
        :data="dataList"
        border
        @selection-change="dataListSelectionChangeHandle"
        @sort-change="dataListSortChangeHandle"
        style="width: 100%;">
        <el-table-column type="selection" header-align="center" align="center" width="50"></el-table-column>
        <el-table-column prop="fileName" :label="$t('fileName')" header-align="center" align="center"></el-table-column>
        <el-table-column prop="createDate" :label="$t('createDate')" header-align="center" align="center"></el-table-column>
        <el-table-column prop="updateDate" :label="$t('updateDate')" header-align="center" align="center"></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="150">
          <template slot-scope="scope">
            <table-button tooltip="设计" icon="el-icon-edit" @click="$router.push({ name: 'iframe', query: { key: 'designer-'+scope.row.fileName, menuId: $route.meta.menuId, url: getDesignerURL(scope.row.fileName) } })"></table-button>
            <table-button tooltip="预览" icon="el-icon-view" @click="$router.push({ name: 'iframe', query: { key: 'preview-'+scope.row.fileName, menuId: $route.meta.menuId, url: getPreviewURL(scope.row.fileName) } })"></table-button>
            <table-button role="delete" @click="deleteHandle(scope.row.id)"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import Cookies from 'js-cookie'
import qs from 'qs'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: true,
        getDataListURL: '/ur2/report/page',
        getDataListIsPage: true,
        deleteURL: '/ur2/report',
      },
      dataForm: {
        fileName: ''
      }
    }
  },
  methods: {
    // 设计器url地址
    getDesignerURL (fileName) {
      let params = this.getParams(fileName)
      return `${window.SITE_CONFIG['apiURL']}/ur2/ureport/designer?${params}`
    },
    // 预览url地址
    getPreviewURL (fileName) {
      let params = this.getParams(fileName)
      return `${window.SITE_CONFIG['apiURL']}/ur2/ureport/preview?${params}`
    },
    getParams (fileName) {
      return qs.stringify({
        'access_token': Cookies.get('access_token'),
        'tenant_code_key': Cookies.get('tenantCode'),
        '_u': 'icloud-' + fileName
      })
    }
  }
}
</script>
