<template>
  <el-card shadow="never" class="aui-card--fill">
    <my-container :left-width="400">
      <template v-slot:left>
        <div class="tree-container">
          <el-tree
              ref="postTree"
              v-if="postTreeVisible"
              class="left-tree"
              v-loading="postTreeLoading"
              :data="postTreeData"
              node-key="id"
              :props="treeProps"
              :load="loadPostNode"
              @node-click="treeNodeClick"
              highlight-current lazy>
              <span slot-scope="{ data }">
                <template>
                  <span v-if="data.archiveType == archiveType.catalog" style="color: #0e9d6a">{{ data.name }}</span>
                  <span v-else>{{ data.name }}</span>
                </template>
              </span>
          </el-tree>
        </div>
      </template>
      <template v-slot:right>
        <div v-show="rightVisible">
          <el-form :inline="true" :model="dataForm">
            <el-form-item>
              <toolbar-button role="save" @click="dataFormSubmitHandle()" v-if="$hasPermission('mps:archive:prepostbind')"></toolbar-button>
            </el-form-item>
            <el-form-item>
              <el-alert title="关联组卷案卷与预组卷目录" type="info" show-icon :closable="false" style="height: 28px"></el-alert>
            </el-form-item>
          </el-form>
          <el-tree
              ref="preTree"
              v-if="preTreeVisible"
              :data="preTreeData"
              v-loading="preTreeLoading"
              :props="treeProps"
              lazy
              :default-checked-keys="preCheckedKeys"
              node-key="id"
              :load="loadPreNode"
              highlight-current
              show-checkbox>
          </el-tree>
        </div>
      </template>
    </my-container>
  </el-card>
</template>

<script>
// 预组卷目录与组卷管理的案卷关联
import debounce from 'lodash/debounce'
import XEUtils from 'xe-utils'

export default {
  data() {
    return {
      dataForm: {
      },
      preTreeLoading: false,
      postTreeLoading: false,
      rightVisible: false,

      treeProps: {
        children: 'children',
        label: 'name',
        isLeaf: this.hasChildren
      },
      
      postTreeVisible: true, // 组卷目录
      preTreeVisible: true, // 预组卷目录

      postTreeData: [], // 组卷目录
      preTreeData: [], // 预组卷目录

      selectedPostCatalog: null, // 当前选中的组卷管理的案卷节点

      preCheckedKeys: [], // 用于反选

      archiveType: { // 节点类型
        outline: 1, // 分类大纲
        catalog: 2, // 案卷目录
        dir: 3 // 卷内目录
      },
    }
  },
  mounted() {
    
  },
  created() {
    this.loadOutlineData()
  },
  methods: {
    // 加载分类大纲
    loadOutlineData() {
      // 左侧：案卷目录及卷内目录
      this.postTreeLoading = true
      this.$http.get('/mps/archiveOutline/postTree').then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.postTreeData = XEUtils.toArrayTree(res.data, {key: 'id', parentKey: 'pid', children: 'children'})
      }).catch(() => {
        return this.$message.error('出错了')
      }).finally(() => {
        this.postTreeLoading = false
      })

      // 右侧：预组卷目录
      this.preTreeLoading = true
      this.$http.get('/mps/archiveOutline/preTree').then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.preTreeData = XEUtils.toArrayTree(res.data, {key: 'id', parentKey: 'pid', children: 'children'})
      }).catch(() => {
        return this.$message.error('出错了')
      }).finally(() => {
        this.preTreeLoading = false
      })

    },

    // 左侧：组卷目录
    loadPostNode(node, resolve) {
      if (node.level != 0) {
        let data = node.data
        if (data.archiveType == this.archiveType.outline) {
          if (data.outlineLeaf) {
            this.loadPostCatalog(data.id, resolve)
          } else {
            resolve(data.children)
          }
        }
      }
    },
    // 加载案卷目录
    loadPostCatalog(outlineId, resolve) {
      this.$http.get('/mps/archivePostCatalog/list',
        {
          params: {
            outlineId: outlineId
          }
        }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        if (res.data != null && res.data.length > 0) {
          res.data.forEach(item => {
            item.archiveType = this.archiveType.catalog // 当前节点类型
            item.outlineLeaf = false // 是否是分类大纲的叶子节点
            item.hasChildren = false // 仅允许在案卷这一级建立与预组卷的关联关系，所以案卷就显示为叶子节点
          })
        }
        resolve(res.data)
      })
    },
    
    loadPreNode(node, resolve) {
      if (node.level != 0) {
        let data = node.data
        if (data.archiveType == this.archiveType.outline) {
          if (data.outlineLeaf) {
            this.loadPreCatalogChildren(data.id, resolve)
          } else {
            resolve(data.children)
          }
        } else if (data.archiveType == this.archiveType.catalog) {
          this.loadPreCatalogChildren(data.id, resolve)
        }
      }
    },

    // 加载预组卷目录
    loadPreCatalogChildren(pid, resolve) {
      this.$http.get(`/mps/archivePreCatalog/children/${pid}`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        if (res.data != null && res.data.length > 0) {
          res.data.forEach(item => {
            item.archiveType = this.archiveType.catalog
          })
        }
        resolve(res.data)
      })
    },
    
    // 点击左侧树节点
    treeNodeClick(data, node, ele) {
      if (!this.selectedPostCatalog || this.selectedPostCatalog.id != data.id) {
        // 清空右侧已勾选的节点
        this.$refs.preTree.setCheckedNodes([])
        this.preCheckedKeys = []
      }
      if (data.archiveType == this.archiveType.catalog) { // 只有选中了案卷才允许与预组卷进行关联
        this.rightVisible = true
        this.selectedPostCatalog = data
        this.$message({
          message: `当前选择的案卷是：${data.name}`,
          type: 'info',
          duration: 5000
        })
        // 查询已经保存的关联关系（可能包括预组卷目录，也可能包括分类大纲），并自动勾选
        this.$http.get(`/mps/archiveQcFiles/${this.selectedPostCatalog.id}/bindingIds`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.preCheckedKeys = res.data
        })
      } else {
        this.rightVisible = false
      }
    },

    dataFormSubmitHandle: debounce(function () {
      let checkedNodes = this.$refs.preTree.getCheckedNodes(false)
      if (checkedNodes == null || checkedNodes.length == 0) {
        this.$confirm(`确定清空【${this.selectedPostCatalog.name}】的所有关联吗？`).then(() => {
          this.doSubmit([])
        })
      } else {
        this.$confirm('确定提交关联吗？').then(() => {
          this.doSubmit(checkedNodes)
        })
      }
    }, 1000, {'leading': true, 'trailing': false}),

    doSubmit(checkedNodes) {
      const loading = this.$loading({
        lock: true,
        text: '正在提交，请稍等...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      let preIds = []
      if (checkedNodes) {
        for (let node of checkedNodes) {
          preIds.push(node.id)
        }
      }
      this.$http.put(`/mps/archiveQcFiles/bindCatalog`, {
        postCatalogId: this.selectedPostCatalog.id,
        preIds: preIds
      }).then(({data: res}) => {
        if (res.code != 0) {
          return this.$alert(res.msg, '', {
            confirmButtonText: '关闭',
            type: "error"
          })
        }
        this.$alert('操作成功！', '', {
          confirmButtonText: '关闭',
          type: "success"
        })
      }).finally(() => {
        loading.close()
      })
    },

    hasChildren(data) {
      return !data.hasChildren
    },
  }
}
</script>
<style lang="scss" scoped>
.tree-container {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 180px);
}
::v-deep .row-red {
  background-color: #FF99FF !important;
}
</style>
